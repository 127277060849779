import React, { useState, useEffect, FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, Typography, Card, CardContent } from '@mui/material';

import ProjectSelector from 'components/common/ProjectSelector';
import SectionHeader from 'components/layout/SectionHeader';
import useCurrentUser from 'hooks/useCurrentUser';
import {
  useGetProjectQuery,
  useAdminGetProjectQuery,
  useAdminGetProjectWithIdMutation,
} from 'services/api/projects.api';
import { Project } from 'services/types/project';

const ProjectView: FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const user = useCurrentUser();
  const [project, setProject] = useState<Project | null>(null);
  const { data } = user?.isAdmin && project ? useAdminGetProjectQuery(project.id) : useGetProjectQuery(null);

  const [getProjectWithId] = useAdminGetProjectWithIdMutation();
  const navigate = useNavigate();
  const { projectId } = useParams<{ projectId: string }>();

  const fetchProject = async () => {
    if (user?.isAdmin && projectId) {
      const intId = parseInt(projectId, 10);
      try {
        const proyecto = await getProjectWithId(intId).unwrap();
        if (proyecto) {
          setProject(proyecto);
        }
      } catch (e) {
        navigate('/looker');
      }
    } else {
      navigate('/looker');
    }
  };

  useEffect(() => {
    fetchProject().catch((e) => console.error(e)); // eslint-disable-line no-console
  }, [projectId]);

  const handleProjectSelect = (selectedProject: Project | null) => {
    if (selectedProject) {
      navigate(`/looker/${selectedProject.id}`);
    }
  };

  useEffect(() => {
    if (data) {
      setProject(data);
    }
  }, [data]);

  const button = (
    <Box alignContent="center" textAlign="left">
      {user?.isAdmin && <ProjectSelector onSelect={handleProjectSelect} projectId={project?.id} />}
    </Box>
  );

  return (
    <Box pb={4} pl="10px" pr="10px">
      <SectionHeader text="Reporte" button={button} />
      <Typography variant="h4" pt="25px">
        {project?.lookerUrl && !user?.subProjectId ? (
          <Card>
            <CardContent>
              <div style={{ position: 'relative', width: '100%', paddingBottom: '70%' }}>
                <iframe
                  title="Reporte en Looker"
                  src={project.lookerUrl}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    border: 0,
                  }}
                  sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
                />
              </div>
            </CardContent>
          </Card>
        ) : (
          'Reporte no disponible'
        )}
      </Typography>
    </Box>
  );
};

export default ProjectView;
