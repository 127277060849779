import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import { DataGrid, GridToolbar, GridColDef } from '@mui/x-data-grid';
import { esES } from '@mui/x-data-grid/locales';

import SectionHeader from 'components/layout/SectionHeader';
import useCurrentUser from 'hooks/useCurrentUser';
import { useGetAlertsQuery } from 'services/api/alert';
import { Alert } from 'services/types/alert';

import ProjectSelector from '../../components/common/ProjectSelector';
import type { Project } from '../../services/types/project';

const Alerts = () => {
  const navigate = useNavigate();
  const currentUser = useCurrentUser();

  const queryParams = new URLSearchParams(window.location.search);
  const queryProjectId = queryParams.get('projectId');
  const [projectParamId, setProjectParamId] = useState<string>(queryProjectId || '');

  const { data: alerts, refetch } = useGetAlertsQuery({
    projectId: projectParamId,
  });

  useEffect(() => {
    queryParams.delete('projectId');
    // save projectId in query params
    if (projectParamId) {
      queryParams.set('projectId', projectParamId);
      navigate(`?${queryParams.toString()}`);
    }

    refetch().catch((e) => console.error(e)); // eslint-disable-line no-console
  }, [projectParamId]);

  const columns: GridColDef<Alert>[] = [
    {
      field: 'id',
      headerName: 'Id',
      width: 80,
    },
    {
      field: 'clientId',
      headerName: 'Link Cliente',
      width: 105,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <a href={`/clients/${params.value}`} target="_blank" rel="noreferrer">
          Ver
        </a>
      ),
    },
    {
      field: 'identifier',
      headerName: 'Tipo de alerta',
      width: 300,
    },
    {
      field: 'messageText',
      headerName: 'Mensaje',
      width: 300,
    },
    //   {
    //     field: 'readAt',
    //     headerName: 'Leido',
    //     width: 70,
    //   },
    //   {
    //     field: 'archivedAt',
    //     headerName: 'Archivado',
    //     width: 70,
    //   },
  ];

  if (alerts && alerts[0]?.projectId && currentUser?.isAdmin) {
    const projectColumn: GridColDef = {
      field: 'Project',
      headerName: 'Inmobiliaria',
      width: 150,
      valueGetter: (value) => (value as { name: string })?.name || '',
    };
    columns.unshift(projectColumn);
  }

  const handleProjectSelect = (project: Project | null) => {
    if (project) {
      setProjectParamId(`${project.id}`);
    }
  };

  return (
    <Box>
      {currentUser?.isAdmin && (
        <ProjectSelector
          onSelect={handleProjectSelect}
          projectId={projectParamId ? Number(projectParamId) : undefined}
          onlyMasterProjects
        />
      )}
      <SectionHeader text="Lista de Alertas" button={null} />
      <Box
        sx={{
          backgroundColor: 'white',
          borderRadius: 4,
          p: 2,
        }}
      >
        {alerts && (
          <DataGrid
            rows={alerts}
            columns={columns}
            slots={{
              toolbar: GridToolbar,
            }}
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            sx={{
              color: 'Black',
              height: 'calc(100vh - 120px)',
              '& .MuiDataGrid-row': {
                cursor: 'pointer',
              },
              '& .MuiDataGrid-row.Mui-selected': {
                backgroundColor: '#e8f0fe',
              },
              border: 'none',
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default Alerts;
