import React, { ReactNode } from 'react';

const prettifyMessage = (text: string): ReactNode[] => {
  const urlRegex = /(```[\s\S]+?```|https?:\/\/[^\s",\\)]+[^.\s",\\)]|\*+\S.*?\S\*+|`\S.*?\S`)/g;
  const nodes: ReactNode[] = text
    .trim()
    .split(urlRegex)
    .map((textSegment, index) => {
      if (textSegment.startsWith('http')) {
        return (
          <a
            href={textSegment}
            key={`${textSegment}-${index + 1}`}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: 'none' }}
          >
            {textSegment}
          </a>
        );
      }
      if (textSegment.startsWith('```') && textSegment.endsWith('```')) {
        const lines = textSegment.slice(3, -3).split('\n');
        return (
          <div
            key={`${textSegment}-${index + 1}`}
            style={{ borderColor: '#d4d4d4', backgroundColor: '#f9f9f9', borderRadius: '0.375rem' }}
          >
            <div
              style={{
                width: '100%',
                fontSize: '0.8rem',
                padding: '8px 16px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <span style={{ color: '#5d5d5d' }}>{lines[0] || 'Bloque de código'}</span>
              <button
                style={{
                  color: '#5d5d5d',
                  cursor: 'pointer',
                  background: 'none',
                  border: 'none',
                }}
                type="button"
                onClick={() => {
                  // eslint-disable-next-line no-console
                  navigator.clipboard.writeText(lines.slice(1).join('\n')).catch(console.error);
                }}
              >
                Copiar
              </button>
            </div>
            <div style={{ width: '100%', padding: '0 16px 16px 16px' }}>
              <span style={{ whiteSpace: 'pre-wrap' }}>{lines.slice(1).join('\n')}</span>
            </div>
          </div>
        );
      }
      if (textSegment.startsWith('`') && textSegment.endsWith('`')) {
        // use mono font for code
        return (
          <code
            key={`${textSegment}-${index + 1}`}
            style={{ backgroundColor: '#ececec', padding: '.15rem .3rem', borderRadius: '.25rem' }}
          >
            {textSegment.slice(1, -1)}
          </code>
        );
      }
      if (textSegment.startsWith('*') && textSegment.endsWith('*')) {
        return <strong key={`${textSegment}-${index + 1}`}>{textSegment.slice(1, -1)}</strong>;
      }
      return textSegment;
    });
  return nodes;
};

export default prettifyMessage;
