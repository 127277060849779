import { Alert } from 'services/types/alert';
import { AlertType } from 'services/types/project';

import baseApi from './baseApi';

const alertApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAlerts: build.query({
      query: (body: { projectId: string }) => ({
        url: `/alerts?projectId=${body.projectId}`,
        method: 'GET',
      }),
      transformResponse: (response: Alert[]): Alert[] => response,
    }),
    postTestAlerts: build.mutation({
      query: (body: { text: string; alertsConfig: AlertType[] }) => ({
        url: '/alerts/test',
        method: 'POST',
        body,
      }),
      transformResponse: (response: { message: string }): { message: string } => response,
    }),
  }),
  overrideExisting: false,
});

export const { useGetAlertsQuery, usePostTestAlertsMutation } = alertApi;
