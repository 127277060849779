import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import Send from '@mui/icons-material/Send';
import { Grid, IconButton, TextField } from '@mui/material';

import Conversation from 'pages/Client/components/Chat';
import Pusher from 'pusher-js';
import { useGetDemoClientMessagesQuery, useCreateDemoChatMessageMutation } from 'services/api/demoChat';

import ChatWrapper from './ChatWrapper';

const PUSHER_APP_KEY = import.meta.env.VITE_PUSHER_APP_KEY || '';

const DemoChat = () => {
  const { companyIdentifier } = useParams<{ companyIdentifier: string }>();
  const localStorageKey = `lidz-pusher-demo-${companyIdentifier}`;
  const clientPhoneIdentifier = localStorage.getItem(localStorageKey);
  const navigate = useNavigate();
  const [messageToSend, setMessageToSend] = useState('');
  const [createDemoChatMessage] = useCreateDemoChatMessageMutation();

  const { data: messages, refetch } = useGetDemoClientMessagesQuery({
    clientPhoneIdentifier: clientPhoneIdentifier ?? '',
  });
  const [refetchFlag, setRefetchFlag] = useState<number>(1);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const messageParam = searchParams.get('message');
    if (messageParam) {
      const autoStartChatParam = searchParams.get('autoStartChat');
      if (autoStartChatParam && clientPhoneIdentifier) {
        createDemoChatMessage({ clientPhoneIdentifier, message: messageParam })
          .then(() => {
            setRefetchFlag(refetchFlag + 1);
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.error('Error sending initial demo chat message', error);
          });
      } else {
        setMessageToSend(messageParam);
      }
      searchParams.delete('message');
      searchParams.delete('autoStartChat');
      setSearchParams(searchParams, { replace: true });
    }
  }, []); // Run only once on component mount

  useEffect(() => {
    refetch().catch(() => {
      // eslint-disable-next-line no-console
      console.error('Error refetching messages');
    });
  }, [refetchFlag]);

  useEffect(() => {
    if (!clientPhoneIdentifier) {
      navigate(`/demochat/${companyIdentifier}/newchat`);
      return () => {};
    }
    const pusher = new Pusher(PUSHER_APP_KEY, {
      cluster: 'sa1',
    });

    const channel = pusher.subscribe(`chat-${clientPhoneIdentifier}`);
    channel.bind('new-message', (data: object) => {
      setRefetchFlag((prev) => prev + 1);
      console.log('New message', JSON.stringify(data)); // eslint-disable-line no-console
    });
    return () => {
      channel.unbind_all();
      pusher.unsubscribe(`chat-${clientPhoneIdentifier}`);
      pusher.disconnect();
    };
  }, [clientPhoneIdentifier, setRefetchFlag]);

  const handleSendMessage = () => {
    if (!clientPhoneIdentifier || !messageToSend) {
      return;
    }
    createDemoChatMessage({ clientPhoneIdentifier, message: messageToSend })
      .then(() => {
        setRefetchFlag(refetchFlag + 1);
        setMessageToSend('');
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error sending demo chat message', error);
      });
  };

  return (
    <ChatWrapper companyIdentifier={companyIdentifier} includeGoToNewChatButton>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
        <Conversation messages={messages || []} isClientView refetch={() => {}} isFilteredConversation clientId={0} />
        <Grid container width="100%" p={1} justifyContent="space-between">
          <TextField
            id="outlined-basic-email"
            label="Mensaje"
            fullWidth
            multiline
            sx={{
              maxWidth: '80%',
              backgroundColor: '#fff',
              borderRadius: '40px',
              opacity: 1,
              mr: 1,
              '& .MuiOutlinedInput-root': { borderRadius: '40px' },
            }}
            value={messageToSend}
            onChange={(e) => setMessageToSend(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && !e.shiftKey && handleSendMessage()}
            autoFocus
          />
          <IconButton
            sx={{
              height: '3.5rem',
            }}
            onClick={handleSendMessage}
          >
            <Send
              sx={{
                fontSize: '2.5rem',
              }}
            />
          </IconButton>
        </Grid>
      </div>
    </ChatWrapper>
  );
};

export default DemoChat;
