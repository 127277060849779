import React, { FC, useState, useEffect } from 'react';

import { Add, Delete, Save } from '@mui/icons-material';
import { Box, TextField, IconButton, Button, Grid, Typography } from '@mui/material';

import PrimaryBtn from 'components/UI/Buttons';
import { usePostTestAlertsMutation } from 'services/api/alert';
import type { EditProjectBody, Project, AlertType } from 'services/types/project';

interface AlertsConfigEditorProps {
  project: Project | null;
  handleEditProject: (projectId: number, data: EditProjectBody) => void;
}

const AlertsConfigEditor: FC<AlertsConfigEditorProps> = ({ project, handleEditProject }) => {
  const [alertsConfigDraft, setAlertsConfigDraft] = useState<AlertType[]>(project?.alertsConfig || []);
  const [testResults, setTestResults] = useState<{ testText: string; result: string | null }[]>(
    project?.alertsConfig?.map(() => ({ testText: '', result: null })) || []
  );
  const [postTestAlerts] = usePostTestAlertsMutation();

  useEffect(() => {
    setAlertsConfigDraft(project?.alertsConfig || []);
    setTestResults(project?.alertsConfig?.map(() => ({ testText: '', result: null })) || []);
  }, [project]);

  const handleAddAlert = () => {
    setAlertsConfigDraft([...alertsConfigDraft, { id: '', description: '', examples: [], actions: [] }]);
    setTestResults([...testResults, { testText: '', result: null }]);
  };

  const handleDeleteAlert = (index: number) => {
    const updatedAlerts = [...alertsConfigDraft];
    const updatedTestResults = [...testResults];
    updatedAlerts.splice(index, 1);
    updatedTestResults.splice(index, 1);
    setAlertsConfigDraft(updatedAlerts);
    setTestResults(updatedTestResults);
  };

  // For identifier and description
  const handleAlertChange = (index: number, key: keyof AlertType, value: string) => {
    const updatedAlerts = [...alertsConfigDraft];
    updatedAlerts[index] = { ...updatedAlerts[index], [key]: value };
    setAlertsConfigDraft(updatedAlerts);
  };

  const fetchTestAlerts = async (text: string, alertsConfig: AlertType[]) => {
    try {
      const response = await postTestAlerts({ text, alertsConfig }).unwrap();
      if (response.message) {
        return response.message;
      }
      return 'No message returned';
    } catch (error) {
      return 'Error al probar la alerta.';
    }
  };

  const handleTestChange = (index: number, value: string) => {
    const updatedTestResults = [...testResults];
    updatedTestResults[index].testText = value;
    setTestResults(updatedTestResults);
  };

  const handleTestAlerts = async (index: number): Promise<void> => {
    const updatedTestResults = [...testResults];
    updatedTestResults[index].result = '';
    const { testText } = testResults[index];

    if (!testText) {
      updatedTestResults[index].result = 'Ingrese un texto para probar.';
      setTestResults(updatedTestResults);
      return;
    }
    const testResponse = await fetchTestAlerts(testText, alertsConfigDraft);
    if (testResponse) {
      updatedTestResults[index].result = testResponse;
      setTestResults(updatedTestResults);
    }
  };

  const handleAddExample = (alertIndex: number) => {
    const updatedAlerts = alertsConfigDraft.map((alert, idx) => {
      if (idx === alertIndex) {
        return {
          ...alert,
          examples: [...alert.examples, ''],
        };
      }
      return alert;
    });
    setAlertsConfigDraft(updatedAlerts);
  };

  const handleExampleChange = (alertIndex: number, exampleIndex: number, value: string) => {
    const tempExamples = [...alertsConfigDraft[alertIndex].examples];
    tempExamples[exampleIndex] = value;
    const updatedAlerts = alertsConfigDraft.map((alert, idx) => {
      if (idx === alertIndex) {
        return {
          ...alert,
          examples: tempExamples,
        };
      }
      return alert;
    });
    setAlertsConfigDraft(updatedAlerts);
  };

  const handleDeleteExample = (alertIndex: number, exampleIndex: number) => {
    const updatedAlerts = alertsConfigDraft.map((alert, idx) => {
      if (idx === alertIndex) {
        return {
          ...alert,
          examples: alert.examples.filter((_, exIdx) => exIdx !== exampleIndex),
        };
      }
      return alert;
    });
    setAlertsConfigDraft(updatedAlerts);
  };

  // TODO: Uncomment this (and the buttons part) when actions are needed
  // const handleAddAction = (alertIndex: number) => {
  //   const updatedAlerts = alertsConfigDraft.map((alert, idx) => {
  //     if (idx === alertIndex) {
  //       return {
  //         ...alert,
  //         actions: [...(alert.actions || []), { function: '', parameters: {} }],
  //       };
  //     }
  //     return alert;
  //   });
  //   setAlertsConfigDraft(updatedAlerts);
  // };

  // const handleActionChange = (
  //   alertIndex: number,
  //   actionIndex: number,
  //   key: keyof AlertAction,
  //   value: string
  // ) => {
  //   const updatedAlerts = [...alertsConfigDraft];
  //   if (!updatedAlerts[alertIndex].actions) {
  //     return;
  //   }
  //   updatedAlerts[alertIndex].actions[actionIndex] = {
  //     ...updatedAlerts[alertIndex].actions[actionIndex],
  //     [key]: value,
  //   };
  //   setAlertsConfigDraft(updatedAlerts);
  // };

  // TODO: handleActionParametersChange

  // const handleDeleteAction = (alertIndex: number, exampleIndex: number) => {
  //   const updatedAlerts = alertsConfigDraft.map((alert, idx) => {
  //     if (idx === alertIndex) {
  //       return {
  //         ...alert,
  //         actions: alert.actions?.filter((_, exIdx) => exIdx !== exampleIndex) || [],
  //       };
  //     }
  //     return alert;
  //   });
  //   setAlertsConfigDraft(updatedAlerts);
  // };

  const handleSaveAlertsConfig = () => {
    const filteredAlertsConfig = alertsConfigDraft.filter((alert) => alert.id && alert.description);
    if (project) {
      handleEditProject(project.id, { alertsConfig: filteredAlertsConfig.length > 0 ? filteredAlertsConfig : null });
    }
  };

  return (
    <Box display="flex" flexDirection="column" gap={2} mt={1}>
      RECUERDA GUARDAR las alertas al terminar de editar y probar.
      {alertsConfigDraft.map((alert, alertIndex) => (
        <Box
          // eslint-disable-next-line react/no-array-index-key
          key={`alert-${alertIndex}`}
          sx={{
            border: '2px solid #a1a1a1',
            borderRadius: '8px',
            padding: '25px',
            mb: 2,
            backgroundColor: '#f6f6f6',
          }}
          display="flex"
          flexDirection="column"
        >
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography variant="h3">Alerta {alertIndex + 1}</Typography>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <TextField
                  label="ID"
                  value={alert.id}
                  onChange={(e) => handleAlertChange(alertIndex, 'id', e.target.value)}
                  fullWidth
                  sx={{ '& input': { padding: '10px 15px' } }}
                />
              </Grid>
              <Grid item xs={4} container justifyContent="flex-end" alignItems="center">
                <IconButton onClick={() => handleDeleteAlert(alertIndex)} color="secondary">
                  <Typography variant="h4">Borrar Alerta</Typography>
                  <Delete />
                </IconButton>
              </Grid>
            </Grid>
            <TextField
              label="Descripción"
              value={alert.description}
              onChange={(e) => handleAlertChange(alertIndex, 'description', e.target.value)}
              fullWidth
              multiline
              rows={2}
              InputProps={{
                inputComponent: 'textarea',
                inputProps: {
                  style: { resize: 'both', overflow: 'auto' },
                },
              }}
            />
          </Box>
          <Box mt={2} display="flex" flexDirection="column">
            - Examples:
            {alert.examples.map((example, exampleIndex) => (
              // eslint-disable-next-line react/no-array-index-key
              <Box key={`example-${exampleIndex}`} display="flex" alignItems="center" mt={1}>
                <TextField
                  value={example}
                  onChange={(e) => handleExampleChange(alertIndex, exampleIndex, e.target.value)}
                  fullWidth
                  // Chnage the padding in the input text
                  sx={{ '& input': { padding: '10px 15px' } }}
                />
                <IconButton onClick={() => handleDeleteExample(alertIndex, exampleIndex)}>
                  <Delete />
                </IconButton>
              </Box>
            ))}
            <Button
              onClick={() => handleAddExample(alertIndex)}
              startIcon={<Add />}
              variant="outlined"
              color="primary"
              style={{ marginTop: 10, maxWidth: 200 }}
            >
              Agregar Ejemplo
            </Button>
          </Box>
          <Box mt={2} display="flex" flexDirection="column">
            - Acciones:
            {alert.actions?.map((action, actionIndex) => (
              // eslint-disable-next-line react/no-array-index-key
              <Box key={`action-${actionIndex}`} mt={1}>
                <TextField
                  label="Función"
                  value={action.function}
                  // onChange={(e) => handleActionChange(alertIndex, actionIndex, 'function', e.target.value)}
                  fullWidth
                />
                {/* <IconButton onClick={() => handleDeleteAction(alertIndex, actionIndex)}>
                  <Delete />
                </IconButton> */}
              </Box>
            ))}
            {/* <Button onClick={() => handleAddAction(alertIndex)} startIcon={<Add />} color="primary">
              Agregar Acción
            </Button> */}
          </Box>
          <Box mt={3} display="flex" flexDirection="column">
            - Probar Alerta:
            <TextField
              label="Texto de prueba"
              value={testResults[alertIndex]?.testText || ''}
              onChange={(e) => handleTestChange(alertIndex, e.target.value)}
              fullWidth
              style={{ marginTop: 10 }}
            />
            <Box display="flex" flexDirection="row" gap={2}>
              <Button
                onClick={() => {
                  handleTestAlerts(alertIndex).catch((error) => {
                    // eslint-disable-next-line no-console
                    console.error('Error al ejecutar:', error);
                  });
                }}
                variant="outlined"
                color="primary"
                style={{ marginTop: 10, maxWidth: 200 }}
              >
                Probar Alertas
              </Button>
            </Box>
            {testResults[alertIndex]?.result && (
              <Box mt={2} p={2} border="1px solid #ddd" borderRadius="8px">
                <Typography variant="body1">Resultado:</Typography>
                <Typography variant="body2">{testResults[alertIndex].result}</Typography>
              </Box>
            )}
          </Box>
        </Box>
      ))}
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 1 }}>
        <PrimaryBtn
          startIcon={<Add />}
          onClick={handleAddAlert}
          variant="contained"
          color="primary"
          sx={{ maxWidth: 200 }}
        >
          Agregar Alerta
        </PrimaryBtn>

        <PrimaryBtn
          startIcon={<Save />}
          onClick={handleSaveAlertsConfig}
          variant="contained"
          color="primary"
          sx={{ marginLeft: 2, maxWidth: 200 }}
        >
          Guardar Alertas
        </PrimaryBtn>
      </div>
    </Box>
  );
};

export default AlertsConfigEditor;
